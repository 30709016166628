import React, { useEffect, useState } from 'react'
import socket from '../../Socket'
import { Grid } from '@mui/material'
import { Navigate, useNavigate } from 'react-router-dom'
import Ticket from '../Ticket'

const SeleccionTurno = () => {
    const [services, setServices] = useState([])
    const [ticket, setTicket] = useState([])
    const [availablePrint, setAvailablePrint] = useState(false)
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : []
    const currentDay = new Date().toLocaleDateString()
    const hour = new Date().toLocaleTimeString()
    const navigate = useNavigate()
    const path = localStorage.getItem('path') ? JSON.parse(localStorage.getItem('path')) : null

    const styleBtn = {
        margin: "1rem",
        height: "75px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2%",
        backgroundColor: "red",
        color: "white",
        cursor: "pointer"
    }

    useEffect(() => {
        if (localStorage.getItem('ticket')) {
            navigate(`${path.path}`)
        }

        socket.emit("buscarServicioHijo", { idPadre: 1 }, (res) => {
            let req = res.filter((service) => service.id_padre === 1)
        })

        socket.on("buscarServicioHijo", (res) => {
            setServices(res.filter((service) => service.nombre !== "PRIORITARIO"))
        })

        socket.on("crearTurno", (res) => {
            setAvailablePrint(true)

            setTicket({ fecha: res.fecha, hora: res.hora, prefijo: res.prefijo, turno: res.numeroTurno })
            
            localStorage.setItem('ticket', JSON.stringify({ fecha: res.fecha, hora: res.hora, prefijo: res.prefijo, turno: res.numeroTurno }))
        })

    }, [])

    const sendData = (data) => {
        socket.emit("crearTurno", data, (res) => { })
    }

    return (
        <Grid container display="flex" justifyContent="center" alignItems="center">
            {!availablePrint ?
                <Grid item xs={12}>
                    <Grid style={{ fontSize: "20px", textAlign: "center", marginTop: "2rem", marginBottom: "1rem" }}>
                        Selecciona el tipo de Turno
                    </Grid>
                </Grid>
                : ""}

            {!availablePrint && services.length ? services.map((data) =>
                <Grid item xs={8} style={styleBtn}
                    onMouseDown={() => {
                        sendData({
                            nombre: user.nombre,
                            dni: user.dniocuit,
                            prioridad: user.prioridad,
                            hijo: data.nombre,
                            tipoTurno: data.prefijo,
                            prioritario: data.prioritario,
                            fecha: currentDay,
                            hora: hour,
                            turno: data.turno
                        })
                    }}
                >
                    {data.nombre}
                </Grid>
            ) : ""}

            {availablePrint ? <Ticket ticketProp={ticket} /> : ""}
        </Grid>
    )
}

export default SeleccionTurno