import React, { useEffect, useState } from 'react'
import { Grid, Button } from '@mui/material'
import Logo from "../../IMAGES/LOGO.webp"
import { BsArrowReturnLeft } from "react-icons/bs"
import { useNavigate } from 'react-router-dom'
import socket from '../../Socket'
import ScheduleIcon from '@mui/icons-material/Schedule';

const Ticket = ({ ticketProp }) => {
    const ticket = localStorage.getItem("ticket") ? JSON.parse(localStorage.getItem("ticket")) : ticketProp
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    const path = localStorage.getItem('path') ? JSON.parse(localStorage.getItem('path')) : null

    const [statusTicket, setStatusTicket] = useState([])
    const navigate = useNavigate()

    const playAudio = () => {
        const audio = document.getElementById("audio")
        audio.autoplay = true
    }

    useEffect(() => {
        
        socket.emit('consultarEstadoTurno', ticket, (res) => { })

        socket.on('consultarEstadoTurno', (res) => {
            const { estado, turno, num_turno, prefijo, tipoturno, fecha, hora_ingreso } = res[0]

            if (estado === "Llamando" || estado === "Atendiendo" || estado === "En Espera") {
                if (ticket.turno === num_turno && ticket.prefijo === tipoturno) {
                    setStatusTicket(res[0])
                    localStorage.setItem('ticket', JSON.stringify({
                        fecha: fecha,
                        hora: hora_ingreso,
                        prefijo: tipoturno,
                        turno: num_turno,

                    }))
                }
            } else {
                if (ticket.turno === num_turno && ticket.prefijo === tipoturno) {
                    localStorage.clear()
                    window.location.href = "/"
                    setStatusTicket([])
                }
            }

        })


    }, [])

    return (
        <Grid container bgcolor="#f5f5f5" style={{ display: "flex", justifyContent: "center" }}>

            <Grid item xs={11} bgcolor="white" style={{ marginTop: "20px", border: "1px solid gray", borderRadius: "5px", paddingBottom: "70px", paddingTop: "50px" }}>
                <Grid m={2} textAlign="center">
                    <img src={Logo} alt="logo edificor" />
                </Grid>

                <Grid m={2} style={{ textAlign: "center", fontSize: "30px", color: "#c2061e" }}>¡Bienvenido a Edificor {user.nombre}!</Grid>
                <Grid m={2} style={{ textAlign: "center", fontSize: "18px" }}>Usted sera atendido por el turno</Grid>

                <Grid m={2} style={{ textAlign: "center", fontSize: "80px", fontWeight: "bold", color: "#c2061e" }}>
                    {ticket.prefijo} {ticket.turno < 10 ? `0${ticket.turno}` : ticket.turno}
                </Grid>

                <Grid m={2} style={{ textAlign: "center", fontSize: "18px" }}>
                    Por favor espere en el sector correspondiente y preste atencion a las pantallas indicativas
                </Grid>


                <audio id="audio">
                    <source src={require("../SeleccionDeTurnos/sonidoTurno.mp3")} type="audio/mp3" />
                </audio>

                {
                    [ticket].length && [statusTicket].length ?
                        <Grid style={{ width: "100%", padding: "10px", textAlign: "center", fontWeight: "bold", letterSpacing: "2px", display: "flex", justifyContent: "center" }}>
                            Estado del Turno: {[statusTicket].length ? statusTicket.estado : ""} <ScheduleIcon style={{ marginLeft: "10px" }} />
                        </Grid>
                        : ""
                }

            </Grid>
        </Grid>
    )
}

export default Ticket