import React from 'react'
import { Button, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const BannerHome = () => {
    const ticket = localStorage.getItem('ticket') ? JSON.parse(localStorage.getItem('ticket')) : null
    const navigate = useNavigate()

    const solicitarTurno = () => {
        if (localStorage.getItem('user')) {
            localStorage.setItem('path', JSON.stringify({ path: window.location.pathname }))
            navigate("/solicitar-turno/seleccion-turno")
        } else {
            localStorage.setItem('path', JSON.stringify({ path: window.location.pathname }))
            navigate("/solicitar-turno/datos")
        }
    }

    const styleBtn = { cursor:"pointer", backgroundColor: "#c2061e", color: "white", fontSize: "20px", padding: "28px", display: "flex", alignItems: "center", justifyContent: "center" }
    
    return (
        <Grid container>
            <Grid item xs={12}>

                <Grid style={{ textAlign: "center", marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                    <img src={require("../IMAGES/LOGO.webp")} alt="" />
                </Grid>
                <Grid style={{ textAlign: "center", marginTop: "15px", color: "red", fontSize: "25px" }}>
                    ¡Bienvenido a Edificor!
                </Grid>
                <Grid style={{ textAlign: "center", margin: "1.5rem" }}>
                    Escanea los precios de nuestro showroom y enterate de nuestras promociones en efectivo,
                    tarjeta de debito o tarjeta de credito.
                </Grid>
                <Grid style={{ textAlign: "center" }}>
                    <img src={require("../IMAGES/qr.gif")} alt="" />
                </Grid>
                <Grid textAlign="center" style={{ position: "absolute", bottom: "0", width: "100%" }} >

                    {ticket
                        ?
                        <Grid style={styleBtn} onClick={() => navigate(`/solicitar-turno/seleccion-turno/${ticket.turno}`)}><ConfirmationNumberIcon style={{ marginLeft: "5px", marginRight: "5px" }} /> Ver mi Turno <ConfirmationNumberIcon style={{ marginLeft: "5px", marginRight: "5px" }} /></Grid>
                        : <Grid style={styleBtn} onClick={() => solicitarTurno()}>  <ConfirmationNumberIcon style={{ marginLeft: "5px", marginRight: "5px" }} /> Solicitar Turno Atencion  <ConfirmationNumberIcon style={{ marginLeft: "5px", marginRight: "5px" }} /></Grid>
                    }
                </Grid>
            </Grid>
        </Grid>

    )
}

export default BannerHome