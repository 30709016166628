import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VistaPrecios from "./Components/VistaPrecios";
import SeleccionTurno from "./Components/SolicitarTurno/SeleccionDeTurnos";
import Formulario from "./Components/SolicitarTurno/Formulario";
import Ticket from "./Components/SolicitarTurno/Ticket";
import BannerHome from "./Components/BannerHome";
import socket from "./Components/Socket";
import ModalEncuesta from "./Components/ModalEncuesta";

const preguntas = [
  {
    pregunta: "¿Qué tan probable es que nos recomiendes a un familiar o amigo?",
    respuesta: "",
    id: "1"
  },
  {
    pregunta: "¿Qué tan satisfecho se siente con la atencion de la RECEPCIONISTA?",
    respuesta: "",
    id: "2"
  },
  {
    pregunta: "¿Qué tan satisfecho se siente con la atencion del VENDEDOR/A?",
    respuesta: "",
    id: "3"
  },
  {
    pregunta: "¿Qué tan satisfecho se sintió con la atencion de la CAJA?",
    respuesta: "",
    id: "4"
  },
]


function App() {
  const [questions, setQuestions] = useState(preguntas)
  const [statusTicket, setStatusTicket] = useState("")
  const ticket = localStorage.getItem("ticket") ? JSON.parse(localStorage.getItem("ticket")) : []
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(false);

  const handleClose = () => setOpen(false);

  const removeQuestion = (rowData) => {
    if (questions.length) {
      setQuestions(questions.filter((question) => question.id !== rowData.id))
      alert(`socket con respuesta ${rowData.response}`)
    } else {
      handleClose()
    }
  }

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: "none",
    bgcolor: 'background.paper',
    borderRadius: "3px",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (localStorage.getItem('ticket')) {
      socket.emit('consultarEstadoTurno', ticket, (res) => { })

      if (questions.length) {
        handleOpen()
      } else {
        handleClose()
      }

      socket.emit('consultarEstadoTurno', ticket, (res) => { })

      socket.on('consultarEstadoTurno', (res) => {
        const { estado, turno, num_turno, prefijo, tipoturno, fecha, hora_ingreso } = res[0]

        if (estado === "Llamando" || estado === "Atendiendo" || estado === "En Espera") {
            if (ticket.turno === num_turno && ticket.prefijo === tipoturno) {
                setStatusTicket(res[0])
                localStorage.setItem('ticket', JSON.stringify({
                    fecha: fecha,
                    hora: hora_ingreso,
                    prefijo: tipoturno,
                    turno: num_turno,
                }))
            }
        } else {
            if (ticket.turno === num_turno && ticket.prefijo === tipoturno) {
                localStorage.clear()
                window.location.href = "/"
                setStatusTicket([])
            }
        }

    })


    }
  }, [])



  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {/* {isLoading */}
        {/* && statusTicket
          && statusTicket === "Finalizado"
          && questions.length
          ? <ModalEncuesta questions={questions} setQuestions={setQuestions} removeQuestion={removeQuestion} />
          : */}
        <Routes>
          <Route path="/" element={<BannerHome />} />
          <Route path="/:id/:sucursal" element={<VistaPrecios />} />
          <Route path="/solicitar-turno" element={<BannerHome />} />
          <Route path="/solicitar-turno/datos" element={<Formulario />} />
          <Route path="/solicitar-turno/seleccion-turno" element={<SeleccionTurno />} />
          <Route path="/solicitar-turno/seleccion-turno/:idTicket" element={<Ticket />} />
        </Routes>
        {/* } */}
      </BrowserRouter>
    </>
  );
}

export default App;
