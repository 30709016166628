import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { precios_qrs } from './UrlFetch';
import LOGO from '../IMAGES/logo_edif.png';
import { BsBoxSeam, BsCoin } from 'react-icons/bs';
import { GoFile } from 'react-icons/go';
import ImgSinStock from '../IMAGES/SinStock.jpg';
import socket from '../Socket';
import ModalSimulador from '../Simulador/ModalSimulador';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const VistaPrecios = () => {
  const { id, sucursal } = useParams();
  const [item, setItem] = useState('');
  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [imageDefault, setImageDefault] = useState('');
  const [typeOfPrice, setTypeOfPrice] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const ticket = localStorage.getItem('ticket') ? JSON.parse(localStorage.getItem('ticket')) : null;
  const [openModalSimulador, setOpenModalSimulador] = useState(false);
  const iframeRef = useRef(null);

  const navigate = useNavigate();
  const urlFloori = `https://edificor.floori.io/?sku=${id}`;

  const getData = () => {
    fetch(`${precios_qrs}/${id}/${sucursal}`, { method: 'GET' })
      .then((data) => data.json())
      .then((res) => {
        setItem(res[0]);
        let resultsImages = [];

        if (res[0].urlimg !== null) {
          res[0].urlimg.split('|').forEach((element, index) => {
            resultsImages.push({ id: index, url: element });
          });
          setImages(resultsImages);
          setImageDefault(resultsImages[0].url);
        }

        if (res !== 'No hay un qr con ese id') {
          if (res[0].precioListaCaja === 0 && res[0].precioCaja === 0) {
            setTypeOfPrice({
              unitario: res[0].precioM2,
              lista: res[0].precioListaM2,
              type: 'M2',
            });
          } else {
            setTypeOfPrice({
              unitario: res[0].precioCaja,
              lista: res[0].precioListaCaja,
              type: 'Unitario',
            });
          }
          setIsLoading(false);
        } else {
          setError(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const solicitarTurno = () => {
    if (localStorage.getItem('user')) {
      localStorage.setItem('path', JSON.stringify({ path: window.location.pathname }));
      navigate('/solicitar-turno/seleccion-turno');
    } else {
      localStorage.setItem('path', JSON.stringify({ path: window.location.pathname }));
      navigate('/solicitar-turno/datos');
    }
  };

  const consultar = (e) => {
    const telefono = '5493513052700';
    const mensaje = 'Hola, estoy en el salon viendo el producto: ' + e.id + ' ' + e.descripcion + ' y necesito hacer una consulta.';
    const link = `https://wa.me/${telefono}?text=${encodeURIComponent(mensaje)}`;
    window.open(link, '_blank');
  };

  useEffect(() => {
    if (localStorage.getItem('ticket')) {
      socket.emit('consultarEstadoTurno', ticket, (res) => {
        if (res[0].estado === 'Finalizado') {
          localStorage.clear();
        }
      });
      socket.on('consultarEstadoTurno', (res) => {});
    }
    getData();
  }, []);

  const handleVerSimuladorClick = () => {
    setOpenModalSimulador(true);
  };

  return (
    <>
      {!isLoading && !error ? (
        <div className="flex flex-col items-center">
          <div className="w-full bg-red-600 flex justify-center py-4">
            <img src={LOGO} className="w-56 h-auto" alt="logo edificor" />
          </div>
          <div className="text-center my-4 w-full px-4">
            <p className="text-lg font-bold">¡Descubre nuestro NUEVO SIMULADOR!</p>
            <button
              className="bg-green-600 text-white py-2 px-4 rounded mt-2"
              onClick={handleVerSimuladorClick}
            >
              VER SIMULADOR
            </button>
          </div>

          <div className="text-center">
            <p className="text-lg font-bold">Código: {item.id}</p>
            <p className="text-lg font-bold">{item.descripcion}</p>
          </div>

          <div className="flex justify-center mt-6">
            <div className="w-60 h-60">
              <img
                src={imageUrl ? imageUrl : imageDefault}
                className="w-full h-full object-contain"
                alt={`imagen de ${item.descripcion}`}
              />
            </div>
          </div>

          <div className="text-center my-4 p-4 bg-gray-100 rounded shadow-md w-full max-w-lg">
            {typeOfPrice && (
              <>
                {item.marca === 'MAJA' || item.marca === 'GLASICC' || item.marca === 'PIU' || item.marca === 'AGATA-FAUCETS' || item.marca === 'AQUAGLASS' ? (
                  <p className="text-2xl font-bold text-red-600">PRODUCTO A PEDIDO</p>
                ) : (
                  item.stock !== '0' && (
                    <p className="text-xl font-bold">
                      <BsBoxSeam className="inline mr-2" /> Unidades Disponibles: {item.stock}
                    </p>
                  )
                )}

                {item.stock === '0' && item.marca !== 'MAJA' && item.marca !== 'GLASICC' && item.marca !== 'PIU' && item.marca !== 'AGATA-FAUCETS' && item.marca !== 'AQUAGLASS' && (
                  <div className="sinStock">
                    <img src={ImgSinStock} alt="imagen sin stock" className="w-full max-w-sm mx-auto" />
                  </div>
                )}

                <p className="text-xl font-bold my-2">
                  <BsCoin className="inline mr-2" /> PRECIO APROXIMADO Lista {typeOfPrice.type}: {typeOfPrice.lista}
                </p>
                <p className="text-xl font-bold my-2">
                  <BsCoin className="inline mr-2" /> PRECIO APROXIMADO Final {typeOfPrice.type}: {typeOfPrice.unitario}
                </p>

                <div className="flex justify-center mt-4">
                  {ticket ? (
                    <button
                      className="bg-red-600 text-white py-2 px-4 rounded mr-2"
                      onClick={() => navigate(`/solicitar-turno/seleccion-turno/${ticket.turno}`)}
                    >
                      Ver mi Turno
                    </button>
                  ) : (
                    <button
                      className="bg-red-600 text-white py-2 px-4 rounded mr-2"
                      onClick={solicitarTurno}
                    >
                      Solicitar Turno Atención
                    </button>
                  )}
                  <button
                    className="bg-green-600 text-white py-2 px-4 rounded"
                    onClick={() => consultar(item)}
                  >
                    <WhatsAppIcon className="inline mr-2" />
                    Consultanos
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="w-full border-t border-gray-300 my-4"></div>

          <div className="text-center w-full max-w-lg p-4 border rounded shadow-md">
            <p className="text-lg font-bold">
              <GoFile className="inline mr-2" /> Ficha Técnica
            </p>
            <p className="text-lg font-bold">Marca: {item.marca}</p>
            {item.caracteristicas &&
              item.caracteristicas.map((element) => (
                <div className="flex justify-between text-sm my-2" key={element[1]}>
                  <span className="font-bold">{element[0]}</span>
                  <span>{element[1]}</span>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center vh-100">
          <div className="text-center">
            <p className="text-2xl my-4">Ups . . . No encontramos resultados</p>
            <img src={require('../IMAGES/cancelar.png')} className="w-24 mx-auto" alt="error pagina no encontrada" />
          </div>
        </div>
      )}

      {openModalSimulador && <ModalSimulador setOpenModal={setOpenModalSimulador} openModal={openModalSimulador} urlFloori={urlFloori} />}
    </>
  );
};

export default VistaPrecios;
