import React, { useEffect, useState } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const ModalVendedor = ({setOpenModal, openModal, urlFloori}) => {

    const handleClose = () => setOpenModal(false);

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height:'100%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    };

    const handleClick = () => {setOpenModal(true)}

    
    return (
      <>
         <> 
          <Modal
            open={handleClick}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
                <iframe  src={urlFloori} style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
            </Box>
          </Modal>
         </>
      </>
    );
  };
  
  export default ModalVendedor;
  